import React from 'react';

const ContactForm = () => (
    <div>
        <form name="contact" method="post" netlify-honeypot="bot-field" data-netlify="true">
            <div className="form-row">
                <div className="form-field">
                    <input type="text" name="name" id="name" placeholder="Name" className="form-input" required />
                </div>
                <div className="form-field">
                    <input type="email" name="email" id="email" placeholder="Email" className="form-input"  />
                </div>
            </div>
            <div className="form-field">
                <input type="text" name="phone" id="phone" placeholder="Phone" className="form-input" required />
            </div>
            <div className="form-field">
                <input type="text" name="subject" id="subject" placeholder="Subject" className="form-input" required />
            </div>
            <div className="form-field">
                <textarea name="message" id="message" rows="5" placeholder="Message" className="form-input"  required/>
            </div>
            <div className="form-actions">
                <button type="submit" className="button button--primary" >Submit</button>
                <input type="reset" value="Clear" className="button button--secondary" />
            </div>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
        </form>
    </div>
)

export default ContactForm