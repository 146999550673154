import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block/title-block"
import ContactForm from "../components/forms/contact-form"
import FbIcon from "../assets/fb-icon.svg"

const ContactPage = () => (
  <Layout>
    <SEO title="Get in touch" description="For any enquiries please fill in the form or reach us at office@rpmaccounting.co.nz" />
    <TitleBlock title="Contact Us" subtitle="" />
    <div className="container">
      {/* <h2>Get in touch</h2> */}
      <p>If you wish to reach out or just ask a quick question, feel free to fill to form below or email us at <a href="mailto:office@rpmaccounting.co.nz" >office@rpmaccounting.co.nz</a>
      </p>
      <div className="row">
        <div className="col-md-6">
          <ContactForm />
        </div>
        <div className="col-md-6">
          <div className="dark__block">
            <h3 className="">Contact Details</h3>
            <ul className="contact__list">
              <li>
                <p><strong>E:</strong> <a href="mailto:office@rpmaccounting.co.nz">office@rpmaccounting.co.nz</a></p>
              </li>
              <li>
                <p><strong>M:</strong> <a href="tel:027 427 9880">027 427 9880</a></p>
              </li>
              <li>
                <p><strong>W:</strong> www.rpmaccounting.co.nz</p>
              </li>
              <li>
                <a href="https://www.facebook.com/rpmaccountingnz"  className="social__icon" target="_blank" rel="noreferrer"><FbIcon /></a>              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout >
)

export default ContactPage
